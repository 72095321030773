import { getAnalytics } from "firebase/analytics";
import { getRemoteConfig, getValue } from "firebase/remote-config";
import { initializeApp } from "firebase/app";

export const appSettings = {
  appName: 'Messaging'
};

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const remoteConfig = getRemoteConfig(app);
remoteConfig.settings.minimumFetchIntervalMillis = 60000;

remoteConfig.defaultConfig = {
  "enable_iotec_wallet_payment_method": false,
  "max_credits_top_up": 20000000,
  "iotec_bank_name": "Stanbic",
  "iotec_ugx_bank_account_number": "9030020293909",
  "iotec_usd_bank_account_number": "9030020370032",
  'feedback_survey_code': 'customer_feedback_001',
  'feedback_survey_url': 'www.iotec.io',
  'feedback_survey_title': 'We value your feedback',
  'feedback_survey_message': 'Please take a moment to share your experience using our app. It won\'t take more than 5 minutes. Your insights will help us improve our services.',

};

export const gtmConfig = {
  containerId: process.env.REACT_APP_GTM_CONTAINER_ID
};

export const remoteConfigData = {
  enableIotecWalletPaymentMethod: () => {
    return getValue(remoteConfig, 'enable_iotec_wallet_payment_method').asBoolean();
  },
  feedbackSurveyCode: () => {
    return getValue(remoteConfig, 'feedback_survey_code').asString();
  },
  feedbackSurveyUrl: () => {
    return getValue(remoteConfig, 'feedback_survey_url').asString();
  },
  feedbackSurveyTitle: () => {
    return getValue(remoteConfig, 'feedback_survey_title').asString();
  },
  feedbackSurveyMessage: () => {
    return getValue(remoteConfig, 'feedback_survey_message').asString();
  },
};
