import * as yup from 'yup';
import dayjs from "dayjs";

export const invalidInputs: any[] = [null, 'null', 'undefined', undefined, ''];
export const reqMsg = 'This field is required';
export const reqMsgInvalid = 'Invalid input';
export const futureDateMsg = 'Please select a date in the past or today';
export const dateFormats = ['YYYY-MM-DDTHH:mm:ssZ', 'DD/MM/YYYY',  'YYYY/MM/DD', 'YYYY-MM-DD', 'YYYY.MM.DD', 'DD.MM.YYYY'];

export const reqNumberYear = yup
  .number().min(1400, reqMsgInvalid)
  .max(5099, reqMsgInvalid)
  .required(reqMsg)
  .nonNullable();

export const reqString = yup
  .string()
  .test('is-not-white-space-only', reqMsg, (value) => value && value.trim().length > 0)
  .required(reqMsg)
  .min(1)
  .notOneOf(invalidInputs, reqMsg);

export const reqPasswordMatch = yup
  .string()
  .required(reqMsg)
  .oneOf([yup.ref('password')], 'Passwords must match');

export const reqPinMatch = yup
  .string()
  .required(reqMsg)
  .oneOf([yup.ref('pin')], 'PINS must match');

export const reqEmail = yup
  .string()
  .email('Must be a valid email')
  .required(reqMsg)
  .notOneOf(invalidInputs, reqMsg);

export const email = yup
  .string()
  .email('Must be a valid email')
  .nullable();

export const reqDate = yup
  .date()
  .required(reqMsg)
  .notOneOf(invalidInputs, reqMsg);

export const reqPastDate = yup
  .date()
  .required(reqMsg)
  .notOneOf(invalidInputs, reqMsg)
  .test('is-not-future-date', futureDateMsg, function (value) {
    if (value) {
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0); // Set the time to midnight to compare dates only
      value.setHours(0, 0, 0, 0);
      return value <= currentDate;
    }
    return true; // If value is not provided, don't perform the test
  });

function parseDateString(value, originalValue) {
  return dayjs(originalValue, dateFormats).toDate();
}

export const reqBirthday = yup.date()
  .transform(parseDateString)
  .max(dayjs(), 'Cannot be in the future')
  .required('Required');

export const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const reqPhone = yup
  .string()
  .matches(phoneRegExp, 'Phone number is not valid')
  .required(reqMsg)
  .notOneOf(invalidInputs, reqMsg);

export const validatePhone = yup
  .string()
  .matches(phoneRegExp, 'Phone number is not valid')
  .nullable();


export const reqObject = yup
  .object()
  .required(reqMsg)
  .notOneOf(invalidInputs, reqMsg);

export const reqArray = yup
  .array()
  .ensure()
  .required(reqMsg)
  .min(1);

export const reqPositiveInteger = yup
  .number()
  .required(reqMsg)
  .positive()
  .integer();

export const reqNumber = yup
  .number()
  .notOneOf([null, 0], reqMsg)
  .required(reqMsg);
export const reqNumberIncludingZero = yup
  .number()
  .notOneOf([null, -1], reqMsg)
  .required(reqMsg);

export const req9DigitString = yup.string()
  .matches(/^([0-9]{9})$/, 'Card number should have 9 digits')
  .required(reqMsg)
  .notOneOf(invalidInputs, reqMsg);

export const req14CharAlphaNumericString = yup.string()
  .matches(/^([A-Za-z0-9]{14})$/, 'NIN should have 14 characters in caps')
  .required(reqMsg)
  .notOneOf(invalidInputs, reqMsg);

export const positiveNumberIncludingZero = yup
  .number()
  .positive().nullable();

export const reqPositiveNumberIncludingZero = yup
  .number()
  .min(0, 'Must be a positive number or zero')
  .required(reqMsg);

export const validateStringLength = (min: number, max: number) => yup.string()
  .min(min, `Minimum length is ${min} characters`)
  .max(max, `Maximum length is ${max} characters`)
  .test('is-not-white-space-only', reqMsg, (value) => value && value.trim().length > 0)
  .required(reqMsg)
  .notOneOf(invalidInputs, reqMsg);
