import { lazy, Suspense } from 'react';
import AuthGuard from 'components/AuthGuard';
import DashboardLayout from 'components/dashboard/DashboardLayout';
import GuestGuard from 'components/GuestGuard';
import LoadingScreen from 'components/LoadingScreen';
import MainLayout from 'components/MainLayout';
import CallBack from 'pages/authentication/login/Callback';
import { localRoutes } from "./constants";
const Loadable = (Component: any) => (props: any) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// Authentication pages
const Login = Loadable(lazy(() => import('pages/authentication/Login')));

// analytics pages
const Dashboard = Loadable(lazy(() => import('pages/analytics/dashboard/Dashboard')));

// sms pages
const SmsList = Loadable(lazy(() => import('pages/communication/sms/single/SmsList')));
const BulkSmsList = Loadable(lazy(() => import('pages/communication/sms/bulk/BulkSmsList')));
const BulkSmsCreator = Loadable(lazy(() => import('pages/communication/sms/bulk/BulkSmsCreator')));
const BulkSmsDetails = Loadable(lazy(() => import('pages/communication/sms/bulk/BulkSmsDetails')));

// contacts pages
const ContactList = Loadable(lazy(() => import('pages/general/contacts/Contacts')));
const ContactsUpload = Loadable(lazy(() => import('pages/general/contacts/ContactsUpload')));
const ContactDetails = Loadable(lazy(() => import('pages/general/contacts/ContactDetails')));

const ContactGroupList = Loadable(lazy(() => import('pages/general/groups/ContactGroups')));
const ContactGroupDetails = Loadable(lazy(() => import('pages/general/groups/details/ContactGroupDetails')));

// Template pages
const TemplateList = Loadable(lazy(() => import('pages/communication/templates/Templates')));

// Management pages
const PartnersList = Loadable(lazy(() => import('pages/management/partners/Partners')));
const StaffList = Loadable(lazy(() => import('pages/management/staff/Staff')));
const StaffDetails = Loadable(lazy(() => import('pages/management/staff/details/StaffDetails')));
const Settings = Loadable(lazy(() => import('pages/management/settings/Settings')));
const Invoices = Loadable(lazy(() => import('pages/management/invoices/Invoices')));
const PriceBandsList = Loadable(lazy(() => import('pages/management/price-bands/PriceBands')));

// Admin pages
const SMSKannel = Loadable(lazy(() => import('pages/configuration/SmsKannel')));

// Account pages
const Profile = Loadable(lazy(() => import('pages/account/Profile')));

// Error pages
const AuthorizationRequired = Loadable(lazy(() => import('pages/AuthorizationRequired')));
const NotFound = Loadable(lazy(() => import('pages/NotFound')));
const ServerError = Loadable(lazy(() => import('pages/ServerError')));

const routes: any[] = [
  {
    path: 'authentication',
    children: [
      {
        path: 'login',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        )
      },
      {
        path: 'login-unguarded',
        element: <Login />
      },
      {
        path: 'login-callback',
        element: <CallBack />
      }
    ]
  },
  {
    path: '*',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: 'dashboard',
        element: <Dashboard />
      },
      {
        path: '',
        element: <SmsList />
      },
      {
        path: localRoutes.singleSms,
        children: [
          {
            path: '',
            element: <SmsList />
          }
        ]
      },
      {
        path: localRoutes.bulkSms,
        children: [
          {
            path: '',
            element: <BulkSmsList />
          },
          {
            path: 'create',
            element: <BulkSmsCreator />
          },
          {
            path: ':bulkId',
            element: <BulkSmsDetails />
          }
        ]
      },
      {
        path: 'contacts',
        children: [
          {
            path: '',
            element: <ContactList />
          },
          {
            path: ':contactId',
            element: <ContactDetails />
          },
          {
            path: 'upload',
            element: <ContactsUpload />
          },
        ]
      },
      {
        path: 'contact-groups',
        children: [
          {
            path: '',
            element: <ContactGroupList />
          },
          {
            path: ':groupId',
            element: <ContactGroupDetails />
          }
        ]
      },
      {
        path: localRoutes.smsTemplates,
        children: [
          {
            path: '',
            element: <TemplateList />
          }
        ]
      },
      {
        path: 'staff',
        children: [
          {
            path: '',
            element: <StaffList />
          },
          {
            path: ':staffId',
            element: <StaffDetails />
          }
        ]
      },
      {
        path: 'kannel-status',
        element: <SMSKannel />
      },
      {
        path: 'partners/:partnerId',
        element: <Settings />
      },
      {
        path: 'partners',
        element: <PartnersList />
      },
      {
        path: 'settings',
        children: [
          {
            path: '',
            element: <Settings />
          },
          {
            path: ':partnerId',
            element: <Settings />
          }
        ]
      },
      {
        path: 'invoices',
        element: <Invoices />
      },
      {
        path: 'price-bands',
        element: <PriceBandsList />
      },
      {
        path: 'profile/',
        children: [
          {
            path: '',
            element: <Profile />
          }
        ]
      },
    ]
  },
  {
    path: 'error',
    element: <MainLayout />,
    children: [
      {
        path: '401',
        element: <AuthorizationRequired />
      },
      {
        path: '404',
        element: <NotFound />
      },
      {
        path: '500',
        element: <ServerError />
      },
      {
        path: '*',
        element: <NotFound />
      }
    ]
  }
];

export default routes;
